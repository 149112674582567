<div class="layout-topbar">
  <!-- <a class="layout-topbar-logo" routerLink=""> </a> -->
  <div class="layout-topbar-logo">
    <div style="text-align: center; margin-top: 5px">
      <img src="../../assets/images/logo_text.png" alt="Logo Text" style="width: 100px; height: 30px" />
    </div>
  </div>
  <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
    <i class="pi pi-bars"></i>
  </button>

  <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
    <i class="pi pi-ellipsis-v"></i>
  </button>
  <div #topbarmenu class="layout-topbar-menu" [ngClass]="{ 'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible }">
    <button class="p-link mx-4" (click)="toggleIcon()">
      <i *ngIf="showChatIcon" class="pi pi-comment" style="font-size: 1.5rem" pBadge [value]="chatCount"></i>
      <i *ngIf="!showChatIcon" class="pi pi-home" style="font-size: 1.5rem"></i>
    </button>
    <button class="p-link" (click)="op.toggle($event)">
      <i class="pi pi-bell" style="font-size: 1.5rem"></i>
    </button>
    <p-overlayPanel #op>
      <div class="flex flex-column">
        <p-button label="Notifications" [text]="true" severity="info" [disabled]="true"></p-button>
        <p-button label="Invitations" [text]="true" severity="info" [routerLink]="['/dashboard/notifications']" (click)="op.hide()"></p-button>
      </div>
    </p-overlayPanel>
    <button class="p-link layout-topbar-button" (click)="showProfileDialog($event)">
      <div *ngIf="auth.user$ | async as user">
        <img [src]="user.picture" alt="profile" style="width: 42px; border-radius: 20px; margin-top: 3px" />
      </div>
      <span>Profile</span>
    </button>
    <p-overlayPanel #overlayPanel [dismissable]="false">
      <div class="overlay-content flex flex-column gap-3">
        <div class="profile-content" *ngIf="auth.user$ | async as user">
          <h4>{{ user.nickname }}</h4>
          <p>{{ user.email }}</p>
          <div *ngIf="auth.isAuthenticated$ | async" class="flex flex-column">
            <p-button label="Switch Role" [text]="true" severity="info" icon="pi pi-user" (click)="switchAccount()"></p-button>
            <p-button label="Edit Profile" [text]="true" severity="info" icon="pi pi-user-edit" (click)="editProfile()"></p-button>
            <p-button label="Logout" [text]="true" severity="info" icon="pi pi-lock" (click)="logout()"></p-button>
          </div>
        </div>
      </div>
    </p-overlayPanel>
  </div>
</div>

<p-dialog header="Header" [(visible)]="openEditModal" [modal]="true" [style]="{ width: '40rem' }" [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">
  <ng-template pTemplate="header">
    <div class="inline-flex align-items-center justify-content-center gap-2">
      <span class="font-bold white-space-nowrap">Edit Profile</span>
    </div>
  </ng-template>
  <!-- <p class="m-0"> -->
  <form [formGroup]="editProfileForm">
    <div class="flex flex-column align-items-center w-full">
      <div class="form-group mt-4">
        <span class="p-float-label">
          <input pInputText id="firstName" formControlName="firstName" class="form-control" [style]="{ width: '20rem' }" />
          <label for="firstName">First Name</label>
        </span>
        <div *ngIf="shouldShowRequiredError('firstName')" class="text-danger">First name is required</div>
        <div *ngIf="shouldShowMaxLengthError('firstName')" class="text-danger">Max length exceeded</div>
      </div>
      <div class="form-group mt-4">
        <span class="p-float-label">
          <input pInputText id="lastName" formControlName="lastName" class="form-control" [style]="{ width: '20rem' }" />
          <label for="lastName">Last Name</label>
        </span>
        <div *ngIf="shouldShowRequiredError('lastName')" class="text-danger">Last name is required</div>
        <div *ngIf="shouldShowMaxLengthError('lastName')" class="text-danger">Max length exceeded</div>
      </div>
    </div>
  </form>
  <!-- </p> -->
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="handleEditProfile()" label="Ok" pAutoFocus [autofocus]="true" [disabled]="!editProfileForm.valid"></p-button>
  </ng-template>
</p-dialog>
<p-toast></p-toast>
