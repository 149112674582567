import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class RoleService {
  constructor() {}

  private readonly STORAGE_KEY = "selectedRole";

  setSelectedRole(role: string): void {
    localStorage.setItem(this.STORAGE_KEY, role);
  }

  getSelectedRole(): string | null {
    return localStorage.getItem(this.STORAGE_KEY);
  }
}
