import { NgModule } from "@angular/core";
import { LoginRoutingModule } from "./login-routing.module";
import { LoginComponent } from "./login.component";
import { ButtonModule } from "primeng/button";
import { CheckboxModule } from "primeng/checkbox";
import { PasswordModule } from "primeng/password";
import { InputTextModule } from "primeng/inputtext";
import { SharedModule } from "src/app/shared/shared.module";

@NgModule({
  imports: [LoginRoutingModule, ButtonModule, CheckboxModule, InputTextModule, PasswordModule, SharedModule],
  declarations: [LoginComponent],
})
export class LoginModule {}
