import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class StudentService {
  private universityData: any;
  private dialCodesSource = new BehaviorSubject<any[]>([]);
  private countryCodesSource = new BehaviorSubject<any[]>([]);
  dialCodes$ = this.dialCodesSource.asObservable();
  countryCodes$ = this.countryCodesSource.asObservable();
  constructor() {}

  setUniversityData(data: any) {
    this.universityData = data;
  }

  getUniversityData() {
    return this.universityData;
  }

  setCountryCodes(dialCodes: any[], countryCodes: any[]) {
    this.dialCodesSource.next(dialCodes);
    this.countryCodesSource.next(countryCodes);
  }
}
