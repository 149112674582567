import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanLoad, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Keys } from "../config/keys";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanLoad {
  constructor(private router: Router) {}

  canLoad(): boolean | UrlTree {
    const token = localStorage.getItem(Keys.TOKEN);
    if (token) {
      return true;
    } else return this.router.parseUrl("/");
  }

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    const token = localStorage.getItem(Keys.TOKEN);
    if (token) {
      return this.router.parseUrl("dashboard");
    } else {
      return true;
    }
  }
}
