<div class="layout-wrapper" [ngClass]="containerClass">
  <app-topbar></app-topbar>
  <div class="layout-sidebar" *ngIf="!hideSideNav">
    <app-sidebar></app-sidebar>
  </div>
  <div class="layout-main-container" [ngClass]="{ 'hide-sidenav': hideSideNav }">
    <div class="layout-main">
      <router-outlet></router-outlet>
    </div>
    <!-- <app-footer></app-footer> -->
  </div>
  <!-- <app-config></app-config> -->
  <div class="layout-mask"></div>
</div>
