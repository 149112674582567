import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment as config } from "../../../environments/environment";
import { Observable } from "rxjs";
import { RoleService } from "../role.service";
import { StatsPayload } from "src/app/shared/types/interfaces/stats-payload.interface";
@Injectable({
  providedIn: "root",
})
export class ApiService {
  selectedRole!: string;

  constructor(private http: HttpClient, private roleService: RoleService) {}

  roleList$(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/user/role`);
  }

  pendingList(): Observable<any> {
    return this.http.get(`${config.apiUri}/api/invitation/pending?pageIndex=1&pageSize=10`);
  }

  accessRoles$(): Observable<any> {
    const storedRole = this.roleService.getSelectedRole();
    this.selectedRole = storedRole !== null ? storedRole : "";
    const headers = new HttpHeaders({
      rolename: this.selectedRole,
    });

    const options = { headers };

    return this.http.get(`${config.apiUri}/api/role/role-info/access-role`, options);
  }

  accessChatRoles$(): Observable<any> {
    const storedRole = this.roleService.getSelectedRole();
    this.selectedRole = storedRole !== null ? storedRole : "";
    const headers = new HttpHeaders({
      rolename: this.selectedRole,
    });

    const options = { headers };

    return this.http.get(`${config.apiUri}/api/chat/roles`, options);
  }

  userListWithChatRoles(payload: any) {
    const storedRole = this.roleService.getSelectedRole();
    this.selectedRole = storedRole !== null ? storedRole : "";
    const headers = new HttpHeaders({
      rolename: this.selectedRole,
    });

    const options = { headers };
    return this.http.post(`${config.apiUri}/api/chat/users/list`, payload, options);
  }

  universityList$(searchText: string): Observable<any> {
    const storedRole = this.roleService.getSelectedRole();
    this.selectedRole = storedRole !== null ? storedRole : "";
    const headers = new HttpHeaders({
      rolename: this.selectedRole,
    });

    const options = { headers };

    return this.http.post(`${config.apiUri}/api/invitation/universities?pageIndex=1&pageSize=10`, { textsearch: searchText }, options);
  }

  sendInvite(payload: any) {
    const storedRole = this.roleService.getSelectedRole();
    this.selectedRole = storedRole !== null ? storedRole : "";
    const headers = new HttpHeaders({
      rolename: this.selectedRole,
    });

    const options = { headers };
    return this.http.post(`${config.apiUri}/api/invitation/bulk/send`, payload, options);
  }

  invitationList(searchText: string) {
    const storedRole = this.roleService.getSelectedRole();
    this.selectedRole = storedRole !== null ? storedRole : "";
    const headers = new HttpHeaders({
      rolename: this.selectedRole,
    });

    const options = { headers };
    return this.http.post(`${config.apiUri}/api/invitation/sent/list?pageIndex=1&pageSize=10`, { textsearch: searchText }, options);
  }

  updateStatus(invitationId: string, payload: any) {
    return this.http.put(`${config.apiUri}/api/invitation/${invitationId}/status`, payload);
  }

  studentList(payload: any) {
    const storedRole = this.roleService.getSelectedRole();
    this.selectedRole = storedRole !== null ? storedRole : "";
    const headers = new HttpHeaders({
      rolename: this.selectedRole,
    });

    const options = { headers };
    return this.http.post(`${config.apiUri}/api/invitation/users?pageIndex=1&pageSize=10`, payload, options);
  }

  assignedStudentsList(adminId: string, payload: any) {
    const storedRole = this.roleService.getSelectedRole();
    this.selectedRole = storedRole !== null ? storedRole : "";
    const headers = new HttpHeaders({
      rolename: this.selectedRole,
    });

    const options = { headers };
    return this.http.post(`${config.apiUri}/api/assign/${adminId}/users?pageIndex=1&pageSize=10`, payload, options);
  }

  getAssignedStudents(payload: any) {
    const storedRole = this.roleService.getSelectedRole();
    this.selectedRole = storedRole !== null ? storedRole : "";
    const headers = new HttpHeaders({
      rolename: this.selectedRole,
    });

    const options = { headers };
    return this.http.post(`${config.apiUri}/api/assign/users?pageIndex=1&pageSize=10`, payload, options);
  }

  updateUser(payload: any) {
    return this.http.put(`${config.apiUri}/api/user`, payload);
  }

  getIntotoUsers(payload: any) {
    return this.http.post(`${config.apiUri}/api/user/list?pageIndex=1&pageSize=10`, payload);
  }

  studentFilter(payload: any) {
    const storedRole = this.roleService.getSelectedRole();
    this.selectedRole = storedRole !== null ? storedRole : "";
    const headers = new HttpHeaders({
      rolename: this.selectedRole,
    });

    const options = { headers };
    return this.http.post(`${config.apiUri}/api/reference/filter?pageIndex=1&pageSize=10`, payload, options);
  }

  assignStudent(payload: any) {
    return this.http.post(`${config.apiUri}/api/assign`, payload);
  }

  removeStudent(id: string) {
    return this.http.delete(`${config.apiUri}/api/assign/${id}`);
  }

  sideMenu() {
    const storedRole = this.roleService.getSelectedRole();
    this.selectedRole = storedRole !== null ? storedRole : "";
    const headers = new HttpHeaders({
      rolename: this.selectedRole,
    });

    const options = { headers };
    return this.http.get(`${config.apiUri}/api/role/role-info/sidemenu`, options);
  }

  getUserDetails() {
    return this.http.get(`${config.apiUri}/api/user`);
  }

  getUniversityListStudent() {
    const storedRole = this.roleService.getSelectedRole();
    this.selectedRole = storedRole !== null ? storedRole : "";
    const headers = new HttpHeaders({
      rolename: this.selectedRole,
    });

    const options = { headers };

    return this.http.get(`${config.apiUri}/api/university/role?pageIndex=1&pageSize=10`, options);
  }

  applyForUniversityAdmission(universityId: string) {
    const storedRole = this.roleService.getSelectedRole();
    this.selectedRole = storedRole !== null ? storedRole : "";
    const headers = new HttpHeaders({
      rolename: this.selectedRole,
    });

    const options = { headers };

    return this.http.get(`${config.apiUri}/api/university-config/${universityId}/student`, options);
  }

  getCampusConfig(universityId: string, campusId: any) {
    return this.http.get(`${config.apiUri}/api/campus-config/${universityId}/${campusId}/student`);
  }

  universityFormSubmit(universityId: string, payload: any) {
    return this.http.post(`${config.apiUri}/api/university-form/${universityId}`, payload);
  }

  campusFormSubmit(universityId: string, campusId: any, payload: any) {
    return this.http.post(`${config.apiUri}/api/campus-form/${universityId}/${campusId}`, payload);
  }

  programList(universityId: string, campusId: any, payload: any) {
    return this.http.post(`${config.apiUri}/api/program/university/${universityId}/campus/${campusId}`, payload);
  }

  getUniversityMoreInfo(universityId: string) {
    return this.http.get(`${config.apiUri}/api/university/${universityId}/info`);
  }

  getStatsWithRole(payload: any) {
    // const storedRole = this.roleService.getSelectedRole();
    const storedRole = "university_super_admin";

    this.selectedRole = storedRole !== null ? storedRole : "";
    const headers = new HttpHeaders({
      rolename: this.selectedRole,
    });

    const options = { headers };
    return this.http.post(`${config.apiUri}/api/stats`, payload, options);
  }
  getDashboardDataWithRole(payload: any) {
    // const storedRole = this.roleService.getSelectedRole();
    const storedRole = "university_super_admin";

    this.selectedRole = storedRole !== null ? storedRole : "";
    const headers = new HttpHeaders({
      rolename: this.selectedRole,
    });

    const options = { headers };
    return this.http.post(`${config.apiUri}/api/stats/line-chart`, payload, options);
  }
}
