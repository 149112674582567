export const firebaseConfig = {
  apiKey: "AIzaSyA12aDbCfdoyVIqh2N_nsmTZ930IWZgiKM",
  authDomain: "intoto-dev-67f3e.firebaseapp.com",
  databaseURL: "https://intoto-dev-67f3e-default-rtdb.firebaseio.com",
  projectId: "intoto-dev-67f3e",
  storageBucket: "intoto-dev-67f3e.appspot.com",
  messagingSenderId: "79555208093",
  appId: "1:79555208093:web:acfbb213d2823415028574",
  measurementId: "G-58201YP352",
};
