<div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
  <div class="flex flex-column align-items-center justify-content-center">
    <div class="container h-full" *ngIf="auth.isLoading$ | async; else loaded">
      <div class="flex justify-content-center h-full">
        <p-progressSpinner></p-progressSpinner>
      </div>
    </div>
    <ng-template #loaded>
      <div *ngIf="!loggedIn" style="border-radius: 56px; padding: 0.3rem; background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)">
        <div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius: 53px">
          <div class="text-center mb-5">
            <!-- <img src="assets/demo/images/login/avatar.png" alt="Image" height="50" class="mb-3" /> -->
            <div class="text-900 text-3xl font-medium mb-3">Welcome</div>
            <span class="text-600 font-medium">By signing in you are agreeing our Term and privacy policy</span>
          </div>
          <div class="container flex justify-content-center">
            <div *ngIf="(auth.isAuthenticated$ | async) === false">
              <button pButton pRipple label="Continue" class="p-button-lg" (click)="loginWithRedirect()"></button>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="loggedIn && !showRoles && !isLoading" style="border-radius: 56px; padding: 0.3rem; background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)">
        <div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius: 53px">
          <div class="text-center mb-5">
            <span class="text-600 font-medium">Please enter the below details</span>
          </div>

          <form [formGroup]="userNameForm">
            <div class="text-center mb-3">
              <label for="firstName" class="sr-only">First Name:</label>
              <input id="firstName" type="text" pInputText formControlName="firstName" placeholder="First Name" />
            </div>

            <div class="text-center mb-3">
              <label for="lastName" class="sr-only">Last Name:</label>
              <input id="lastName" type="text" pInputText formControlName="lastName" placeholder="Last Name" />
            </div>

            <div class="text-center mt-5">
              <button pButton pRipple label="Continue" class="p-button-lg" [disabled]="userNameForm.invalid" (click)="handleProfileDetails()"></button>
            </div>
          </form>
        </div>
      </div>

      <div *ngIf="loggedIn && showRoles && multipleRoles" style="border-radius: 56px; padding: 0.3rem; background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 30%)">
        <div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius: 53px">
          <div class="text-center mb-5">
            <span class="text-600 font-medium">Please select a role to continue</span>
          </div>
          <ng-container *ngIf="roleLoading; else rolesContent">
            <p-skeleton height="2rem" styleClass="mb-2"></p-skeleton>
          </ng-container>
          <ng-template #rolesContent>
            <div class="flex flex-column justify-content-between mb-3">
              <div class="flex flex-column justify-content-between mb-3">
                <div class="mb-4" *ngFor="let role of roles">
                  <input type="radio" [id]="role._id" name="role" [value]="role.name" [(ngModel)]="selectedRole" />
                  <label [for]="role._id">{{ role.displayName }}</label>
                </div>
              </div>
            </div>
          </ng-template>
          <!-- Continue Button -->
          <div class="text-center mt-5">
            <button pButton pRipple label="Continue" class="p-button-lg" [disabled]="!hasSelectedRole()" (click)="handleRoleSelection()"></button>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<p-progressSpinner *ngIf="isLoading"></p-progressSpinner>
