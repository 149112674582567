import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CommonModule, HashLocationStrategy, LocationStrategy } from "@angular/common";
import { AppLayoutModule } from "./layout/app.layout.module";
import { AuthModule as AuthGuardModule } from "./components/auth/auth.module";
import { LoginModule } from "./components/auth/login/login.module";
import { UsersModule } from "./components/users/users.module";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthHttpInterceptor, AuthModule } from "@auth0/auth0-angular";
import { environment as env } from "../environments/environment";
import { SafeUrlPipe } from "./utilities/pipes/safePipe";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireDatabaseModule } from "@angular/fire/compat/database";
import { firebaseConfig } from "./utilities/config/firebase.config";
@NgModule({
  declarations: [AppComponent, SafeUrlPipe],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    AppLayoutModule,
    AuthGuardModule,
    LoginModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireDatabaseModule,
    AuthModule.forRoot({
      ...env.auth,
      httpInterceptor: {
        ...env.httpInterceptor,
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
