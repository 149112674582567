import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { MenuItem, MessageService } from "primeng/api";
import { LayoutService } from "./service/app.layout.service";
import { OverlayPanel } from "primeng/overlaypanel";
import { Router } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { DOCUMENT } from "@angular/common";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApiService } from "../services/api/api.service";

@Component({
  selector: "app-topbar",
  templateUrl: "./app.topbar.component.html",
})
export class AppTopBarComponent {
  items!: MenuItem[];
  @ViewChild("overlayPanel")
  overlayPanel!: OverlayPanel;
  openEditModal: boolean = false;
  editProfileForm!: FormGroup;
  showChatIcon: boolean = true;
  chatCount!: string;
  @ViewChild("menubutton") menuButton!: ElementRef;

  @ViewChild("topbarmenubutton") topbarMenuButton!: ElementRef;

  @ViewChild("topbarmenu") menu!: ElementRef;

  constructor(
    public layoutService: LayoutService,
    private messageService: MessageService,
    private api: ApiService,
    private fb: FormBuilder,
    private router: Router,
    public auth: AuthService,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  ngOnInit(): void {
    this.chatCount = String(localStorage.getItem("chatCount"));
    this.auth.user$.subscribe((user) => {
      const accessToken = user?.["token"];
      console.log("Access Token:", user);
    });
    this.editProfileForm = this.fb.group({
      firstName: ["", [Validators.required, Validators.maxLength(50)]],
      lastName: ["", [Validators.required, Validators.maxLength(50)]],
    });
  }
  showProfileDialog(event: Event) {
    this.overlayPanel.toggle(event);
  }

  switchAccount() {
    this.router.navigate(["/auth"]);
  }

  editProfile() {
    this.openEditModal = true;
  }

  toggleIcon() {
    this.showChatIcon = !this.showChatIcon;
    if (this.showChatIcon) {
      this.handleRoleSelection();
    } else {
      this.router.navigate(["/dashboard/chat"]);
    }
  }

  handleRoleSelection() {
    const role = String(localStorage.getItem("selectedRole"));
    this.roleRouting(role);
  }

  roleRouting(role: string) {
    if (role === "default") {
      this.router.navigate(["/dashboard/notifications"]);
    } else if (role === "student") {
      this.router.navigate(["/dashboard/student/university-list"]);
    } else if (role === "university_super_admin") {
      this.router.navigate(["/dashboard/users"]);
    } else if (role === "intoto_admin" || role === "thirdparty_sub_coordinator" || role === "thirdparty_coordinator") {
      this.router.navigate(["/dashboard/users/manage-user"]);
    } else this.router.navigate(["/dashboard/users"]);
  }

  handleEditProfile() {
    const payload = this.editProfileForm.value;
    console.log(payload);
    this.api.updateUser(payload).subscribe({
      next: (res: any) => {
        if (res) {
          this.openEditModal = false;
          this.showSuccess("Profile Updated");
        }
      },
      error: (err) => {
        this.showError(err?.error?.message);
        this.openEditModal = false;
      },
    });
  }

  shouldShowRequiredError(controlName: string): boolean {
    const control = this.editProfileForm.get(controlName);
    return !!control && control.hasError("required") && (control.dirty || control.touched);
  }

  shouldShowMaxLengthError(controlName: string): boolean {
    const control = this.editProfileForm.get(controlName);
    return !!control && control.hasError("maxlength") && (control.dirty || control.touched);
  }
  logout() {
    localStorage.clear();
    this.auth.logout({ logoutParams: { returnTo: this.doc.location.origin } });
  }

  showSuccess(successMessage: string): void {
    this.messageService.add({
      severity: "success",
      summary: "Successful",
      detail: successMessage,
      life: 3000,
    });
  }

  showInfo(infoMessage: string): void {
    this.messageService.add({
      severity: "info",
      summary: "Info",
      detail: infoMessage,
      life: 3000,
    });
  }

  showError(errorMessage: string): void {
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: errorMessage,
      life: 3000,
    });
  }
}
