import { OnInit } from "@angular/core";
import { Component } from "@angular/core";
import { LayoutService } from "./service/app.layout.service";
import { RoleService } from "../services/role.service";
import { ApiService } from "../services/api/api.service";
import { ActivatedRoute } from "@angular/router";
import { StudentService } from "../services/student/student.service";

@Component({
  selector: "app-menu",
  templateUrl: "./app.menu.component.html",
})
export class AppMenuComponent implements OnInit {
  model: any[] = [];
  selectedRole: string;
  modelstudent: any[] = [];
  constructor(private studentService: StudentService, public layoutService: LayoutService, private roleService: RoleService, private api: ApiService, private activatedRoute: ActivatedRoute) {
    const storedRole = this.roleService.getSelectedRole();
    this.selectedRole = storedRole !== null ? storedRole : "i_admin";
  }

  ngOnInit() {
    console.log(localStorage.getItem("universityId"));
    const universityId = String(localStorage.getItem("universityId"));
    this.api.applyForUniversityAdmission(universityId).subscribe({
      next: (res: any) => {
        if (res?.info) {
          console.log("student list in side menu", res);
          this.studentService.setUniversityData(res);
          Object.keys(res.info).forEach((key) => {
            let label = "";
            let icon = "";
            let routerLink: any[] = [];
            let items: any[] = [];

            switch (key) {
              case "campusListConfig":
                label = "Campus";
                icon = "pi pi-building";
                break;
              case "personalInformation":
                label = "Personal Details";
                icon = "pi pi-user";
                routerLink = ["/dashboard/student"];
                break;
              case "documentProof":
                label = "Document Proof";
                icon = "pi pi-file";
                break;
              default:
                break;
            }

            if (label !== "") {
              const menuItem: any = {
                label: label,
                icon: icon,
              };

              if (key === "campusListConfig") {
                items = res.info[key].campusList.map((campus: any) => {
                  localStorage.setItem("selectedCampusName", campus.name);
                  localStorage.setItem("selectedCampusId", campus._id);

                  return {
                    label: campus.name,
                    icon: "pi pi-building",
                    routerLink: ["/dashboard/student/campus", campus._id],
                  };
                });
              }

              if (routerLink.length > 0) {
                menuItem.routerLink = routerLink;
              }

              if (items.length > 0) {
                menuItem.items = items;
              }

              if (Object.keys(menuItem).length > 2) {
                this.modelstudent.push(menuItem);
              }
            }
          });
          const universityListItem: any = {
            label: "University List",
            icon: "pi pi-briefcase",
            routerLink: ["/dashboard/student/university-list"],
          };

          this.modelstudent.push(universityListItem);
          console.log("model", this.modelstudent);
        }
      },
    });
    this.selectedRole === "intoto_admin"
      ? (this.model = [
          {
            items: [
              { label: "Home", icon: "pi pi-fw pi-home", routerLink: ["/dashboard/users/manage-user"] },
              // { label: "Manage users", icon: "pi pi-user", routerLink: ["/dashboard/users/manage-user"] },
              { label: "Add User", icon: "pi pi-user-plus", routerLink: ["/dashboard/users/add-user"] },
              {
                label: "Users",
                icon: "pi pi-users",
                routerLink: ["/dashboard/users/student-list"],
              },
            ],
          },
        ])
      : this.selectedRole === "thirdparty_sub_coordinator"
      ? (this.model = [
          {
            items: [
              { label: "Home", icon: "pi pi-fw pi-home", routerLink: ["/dashboard/users/manage-user"] },
              // {
              //   label: "Student List",
              //   icon: "pi pi-users",
              //   routerLink: ["/dashboard/users/manage-user"],
              // },
            ],
          },
        ])
      : this.selectedRole === "university_super_admin"
      ? (this.model = [
          {
            items: [
              { label: "Home", icon: "pi pi-fw pi-home", routerLink: ["/dashboard/users/choose-user"] },
              { label: "Dashboard", icon: "pi pi-chart-bar", routerLink: ["/dashboard/users"] },
              { label: "Add User", icon: "pi pi-user-plus", routerLink: ["/dashboard/users/add-user"] },
              {
                label: "Manage Users",
                icon: "pi pi-users",
                routerLink: ["/dashboard/users/manage-user"],
              },
            ],
          },
        ])
      : this.selectedRole === "student"
      ? (this.model = [
          {
            items: this.modelstudent,
          },
        ])
      : (this.model = [
          {
            items: [
              { label: "Home", icon: "pi pi-fw pi-home", routerLink: ["/dashboard/users/manage-user"] },
              { label: "Add User", icon: "pi pi-user-plus", routerLink: ["/dashboard/users/add-user"] },
              // {
              //   label: isTsubCoord ? "Student List" : "Manage User",
              //   icon: "pi pi-users",
              //   routerLink: ["/dashboard/users/manage-user"],
              // },
            ],
          },
        ]);
  }

  getIconClass = (key: string): string => {
    switch (key) {
      case "HOME":
        return "pi pi-fw pi-home";
      case "ADD_USER":
        return "pi pi-user-plus";
      case "MANAGE_USER":
        return "pi pi-users";
      case "UNIVERSITY_LIST":
        return "pi pi-book";
      case "ALL_USERS":
        return "pi pi-users";
      case "STUDENT_LIST":
        return "pi pi-users";
      case "SEARCH_STUDENT":
        return "pi pi-search";
      default:
        return "pi pi-question";
    }
  };

  getRouterLink = (key: string): string[] => {
    switch (key) {
      case "HOME":
        return ["/dashboard/users"];
      case "ADD_USER":
        return ["/dashboard/users/add-user"];
      case "MANAGE_USER":
        return ["/dashboard/users"];
      case "ALL_USERS":
        return ["/dashboard/users/student-list"];
      default:
        return ["/"];
    }
  };
}
