import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { NotfoundComponent } from "./components/notfound/notfound.component";
import { AppLayoutComponent } from "./layout/app.layout.component";
import { AuthGuard as RouteGuard } from "./utilities/guards/auth.guard";
import { AuthGuard } from "@auth0/auth0-angular";
import { UniversityListComponent } from "./components/student/university-list/university-list.component";

const routes: Routes = [
  { path: "", redirectTo: "/auth", pathMatch: "full" },
  {
    path: "dashboard",
    component: AppLayoutComponent,
    children: [
      {
        path: "users",
        loadChildren: () => import("./components/users/users.module").then((m) => m.UsersModule),
      },
      {
        path: "notifications",
        loadChildren: () => import("./components/notifications/notifications.module").then((m) => m.NotificationsModule),
      },
      { path: "chat", loadChildren: () => import("./components/chat/chat.module").then((m) => m.ChatModule), canActivate: [RouteGuard] },
      { path: "student", loadChildren: () => import("./components/student/student.module").then((m) => m.StudentModule) },
    ],
    canActivate: [AuthGuard],
  },
  { path: "auth", loadChildren: () => import("./components/auth/auth.module").then((m) => m.AuthModule), canActivate: [RouteGuard] },

  { path: "notfound", component: NotfoundComponent },
  { path: "**", redirectTo: "/notfound" },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled", anchorScrolling: "enabled", onSameUrlNavigation: "reload" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
