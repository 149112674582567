import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TabViewModule } from "primeng/tabview";
import { DropdownModule } from "primeng/dropdown";
import { ContextMenuModule } from "primeng/contextmenu";
import { TableModule } from "primeng/table";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { ButtonModule } from "primeng/button";
import { CheckboxModule } from "primeng/checkbox";
import { PasswordModule } from "primeng/password";
import { InputTextModule } from "primeng/inputtext";
import { ToastModule } from "primeng/toast";
import { ConfirmationService, MessageService } from "primeng/api";
import { TabMenuModule } from "primeng/tabmenu";
import { DialogModule } from "primeng/dialog";
// import { DialogComponent } from './components/dialog/dialog.component';
import { CalendarModule } from "primeng/calendar";
import { AutoCompleteModule } from "primeng/autocomplete";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { SkeletonModule } from "primeng/skeleton";
import { CardModule } from "primeng/card";
import { RadioButtonModule } from "primeng/radiobutton";
import { FileUploadModule } from "primeng/fileupload";
import { DragDropModule } from "primeng/dragdrop";
import { TreeModule } from "primeng/tree";
import { AccordionModule } from "primeng/accordion";
import { ChartModule } from "primeng/chart";
import { DialogService } from "primeng/dynamicdialog";

import { ConfirmDialogModule } from "primeng/confirmdialog";

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  TabViewModule,
  DropdownModule,
  ContextMenuModule,
  TableModule,
  OverlayPanelModule,
  ButtonModule,
  InputTextModule,
  CheckboxModule,
  PasswordModule,
  ToastModule,
  TabMenuModule,
  DialogModule,
  CalendarModule,
  AutoCompleteModule,
  ProgressSpinnerModule,
  SkeletonModule,
  CardModule,
  RadioButtonModule,
  FileUploadModule,
  DragDropModule,
  AccordionModule,
  TreeModule,
  ChartModule,
  ConfirmDialogModule,
];

@NgModule({
  imports: [modules],
  exports: [modules],
  declarations: [
    // DialogComponent
  ],
  providers: [MessageService, DialogService, DatePipe, ConfirmationService],
})
export class SharedModule {}
