import { Component } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/compat/database";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { MessageService } from "primeng/api";
import { map } from "rxjs";
import { LayoutService } from "src/app/layout/service/app.layout.service";
import { ApiService } from "src/app/services/api/api.service";
import { RoleService } from "src/app/services/role.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styles: [
    `
      :host ::ng-deep .pi-eye,
      :host ::ng-deep .pi-eye-slash {
        transform: scale(1.6);
        margin-right: 1rem;
        color: var(--primary-color) !important;
      }
    `,
  ],
})
export class LoginComponent {
  valCheck: string[] = ["remember"];
  loggedIn: boolean = false;
  password!: string;
  selectedRole!: string;
  roles: any[] = [];
  roleLoading: boolean = false;
  user: any;
  userNameForm: FormGroup;
  showRoles: boolean = false;
  multipleRoles: boolean = false;
  isLoading: boolean = false;
  visited: boolean = false;
  firebaseUsers!: any[];

  constructor(
    private messageService: MessageService,
    public auth: AuthService,
    public layoutService: LayoutService,
    private fb: FormBuilder,
    private router: Router,
    private roleService: RoleService,
    private api: ApiService,
    private db: AngularFireDatabase
  ) {
    this.userNameForm = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
    });
    this.auth.isAuthenticated$.subscribe((isAuthenticated) => {
      this.loggedIn = isAuthenticated;

      if (isAuthenticated) {
        this.auth.user$.subscribe((user) => {
          console.log(user);
          this.getUsersFromFirebase();
          this.user = user;
          if (this.user?.name === "" || this.user?.name == undefined) {
            this.showRoles = false;
          } else {
            this.showRoles = true;
          }
        });

        this.showRoles = true;
        this.getRoleList();
      }
    });
  }

  loginWithRedirect() {
    this.auth.loginWithRedirect();
  }

  getUsersFromFirebase() {
    this.db
      .list("users")
      .snapshotChanges()
      .pipe(map((actions: any) => actions.map((a: any) => ({ key: a.key, data: a.payload.val() }))))
      .subscribe((usersData: any) => {
        this.firebaseUsers = usersData;
        console.log(usersData);
        const auth0Email = this.user.email;
        const existingUser = this.firebaseUsers.find((user: any) => user.data?.email === auth0Email);
        console.log(existingUser);
        if (existingUser) {
          console.log("User already exists in Firebase");
          localStorage.setItem("userId", existingUser.key);
          this.db
            .list(`/inbox/${existingUser.key}`)
            .snapshotChanges()
            .pipe(map((actions: any) => actions.map((a: any) => ({ key: a.key, data: a.payload.val() }))))
            .subscribe((inboxData: any) => {
              console.log(inboxData);
              let totalUnreadCount = 0;
              inboxData.forEach((item: any) => {
                totalUnreadCount += item.data.unreadMessageCount;
              });

              // Store the total count in localStorage
              localStorage.setItem("chatCount", totalUnreadCount.toString());
            });
        } else {
          console.log("User does not exist");
          // this.createUserInFirebase();
        }
      });
  }

  createUserInFirebase() {
    const newUser = {
      email: this.user.email,
      name: this.user.name,
      picture: this.user.picture,
    };

    // Push the new user to Firebase users list
    this.db
      .list("users")
      .push(newUser)
      .then(() => console.log("User created successfully"))
      .catch((error) => console.error("Error creating user:", error));
  }

  getRoleList() {
    this.roleLoading = true;
    this.api.roleList$().subscribe({
      next: (res: any) => {
        if (res?.info) {
          this.roleLoading = false;
          if ((res?.info.firstName === "" || res?.info.lastName === "") && this.visited) {
            this.showRoles = false;
            this.isLoading = false;
          } else if (res.info?.roles.length <= 1) {
            this.multipleRoles = false;
            const role = res.info?.roles[0].name;
            localStorage.setItem("selectedRole", role);
            this.roleService.setSelectedRole(role);
            this.roleRouting(res.info?.roles[0].name);
          } else {
            this.multipleRoles = true;
            this.roles = res.info?.roles;
            console.log(res);
          }
        }
      },
      error: () => {
        this.roleLoading = false;
      },
    });
  }

  hasSelectedRole(): boolean {
    return !!this.selectedRole;
  }

  handleProfileDetails() {
    const payload = this.userNameForm.value;
    console.log(payload);
    this.api.updateUser(payload).subscribe({
      next: (res: any) => {
        if (res) {
          this.showSuccess("Profile Updated");
          this.showRoles = true;
          this.getRoleList();
        } else {
          console.log("error");
          this.showRoles = true;
          this.getRoleList();
        }
      },
      error: (err) => {
        console.log(err);
        this.visited = true;
        this.showRoles = true;
        this.getRoleList();
        this.showError(err?.error?.message);
      },
    });
  }

  handleRoleSelection() {
    localStorage.setItem("selectedRole", this.selectedRole);
    this.roleService.setSelectedRole(this.selectedRole);
    console.log("Selected Roles:", this.selectedRole);
    this.roleRouting(this.selectedRole);
  }

  roleRouting(role: string) {
    if (role === "default") {
      this.router.navigate(["/dashboard/notifications"]);
    } else if (role === "student") {
      this.router.navigate(["/dashboard/student/university-list"]);
    } else if (role === "university_super_admin") {
      this.router.navigate(["/dashboard/users"]);
    } else if (role === "intoto_admin" || role === "thirdparty_sub_coordinator" || role === "thirdparty_coordinator") {
      this.router.navigate(["/dashboard/users/manage-user"]);
    } else this.router.navigate(["/dashboard/users"]);
  }

  showSuccess(successMessage: string): void {
    this.messageService.add({
      severity: "success",
      summary: "Successful",
      detail: successMessage,
      life: 3000,
    });
  }

  showInfo(infoMessage: string): void {
    this.messageService.add({
      severity: "info",
      summary: "Info",
      detail: infoMessage,
      life: 3000,
    });
  }

  showError(errorMessage: string): void {
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: errorMessage,
      life: 3000,
    });
  }
}
