export const environment = {
  production: false,
  apiUri: "https://dev.intoto.ca",
  auth: {
    domain: "dev-intoto.us.auth0.com",
    clientId: "GkXHnvsdBdLdO8YHfb6i2jYtyiToudbW",
    authorizationParams: {
      audience: "intotodev",
      redirect_uri: window.location.origin,
    },
    errorPath: "/error",
  },
  httpInterceptor: {
    allowedList: ["https://dev.intoto.ca/*"],
  },
};

// export const environment = {
//   production: false,
//   auth: {
//     domain: "stage-intoto.us.auth0.com",
//     clientId: "E2j9EaOMg5PiU5SDSOwMPfCgFEsnp3Lz",
//     authorizationParams: {
//       audience: "intotostage",
//       redirect_uri: window.location.origin,
//     },
//     errorPath: "/error",
//   },
//   httpInterceptor: {
//     allowedList: ["https://stage.intoto.ca/*"],
//   },
// };
